// DEBUG
// const gui = new GUI({
//     title: 'DEBUG',
//     closeFolders: true
// });
// gui.close();


var audio = new Audio('/music/OnAPianoCloud.mp3');

var videos = [
    'nRLWvnYOn_E',
    'cNbHnsbcliA',
    '_TZVzSE433A',
    '2lrrnnKoihg',
    'UhmNWXwwRAo'
];


let player = document.querySelector("#player");
let welcome = document.querySelector("#welcome");

// let maxVideos = 33; 
// let lastRand = -1;
// let newVideo = () => {
//     let url = '/videos/';

//     let source = player.children[0];

//     let rand = lastRand;
//     while (rand == lastRand)
//     {
//         rand = 1.0 + Math.floor(Math.random() * (maxVideos - 1.0));
//     }
//     lastRand = rand;

//     url = url + rand.toString() + '_c.mp4';
//     console.log(url);
//     source.setAttribute("src", url);
//     player.load();
//     player.play();
// }

let lastRand = -1;
let newVideo = () => {
    let rand = lastRand;
    while (rand == lastRand)
    {
        rand = 1.0 + Math.floor(Math.random() * (videos.length - 1.0));
    }
    lastRand = rand;

}



welcome.addEventListener("click", (event) => {
    welcome.remove();
    audio.play();
});
